import React, { useRef, useState } from "react"
import useNativeLazyLoading from "@charlietango/use-native-lazy-loading"
import { useInView } from "react-intersection-observer"
import commonUtil from "../utils/common-util"

const LazyImage = ({
    src,
    srcSet,
    sizes,
    aspectratio,
    alt,
    base64,
    presentationwidth,
    width,
    height
}) => {

    // console.log('!!! LazyImage ::')
    // console.log('>>> src: ', src)
    // console.log('>>> srcSet: ', srcSet)
    // console.log('>>> sizes: ', sizes)
    // console.log('>>> aspectratio: ', aspectratio)
    // console.log('>>> alt: ', alt)
    // console.log('>>> base64: ', base64)
    // console.log('>>> presentationwidth: ', presentationwidth)
    // console.log('>>> width: ', width)
    // console.log('>>> height: ', height)

    const imgRef = useRef(null)
    const supportsLazyLoading = useNativeLazyLoading()
    const [wrapperRef, inView] = useInView({
      triggerOnce: true,
    //   skip: supportsLazyLoading !== false,
    })
    const [imgLoaded, setImgLoaded] = useState(inView !== undefined && inView ? true : false)
    const numericAttrNames = ['width','height']
    
    let attrPropSet = commonUtil.stringConfigToProspSet({ string: alt, numericAttrNames: numericAttrNames})

    attrPropSet = attrPropSet.width && attrPropSet.height && !attrPropSet.aspectRatio ? {
        ...attrPropSet,
        aspectRatio: parseFloat((attrPropSet.width / attrPropSet.height).toFixed(2))
    } : attrPropSet

    // console.log('!!!!! supportsLazyLoading => ', supportsLazyLoading)
    // console.log('!!!!! inView => ', inView)
    // console.log('!!!!! attrPropSet => ', attrPropSet)
  
    return (
      <div ref={wrapperRef}
        className={attrPropSet.wrapperClass ? "image-wrapper " + attrPropSet.wrapperClass : 'image-wrapper'}
        style={{
            position: "relative",
            overflow: "hidden",
            // temp background color until I find out how to parse colors from image in a SSR environment
            // or somehow access image node and get colors from `gatsby-plugin-extract-image-color`
            backgroundColor: "#ffffff00",
        }}
      >
        <div
            style={{
            width: "100%",
            paddingBottom: `${aspectratio ? 100 / aspectratio : attrPropSet.aspectRatio ? 100 / attrPropSet.aspectRatio : 100}%`,
            }}
        />
        <div
            title={attrPropSet.title ? attrPropSet.title : ''}
            style={{
            position: "absolute",
            inset: "0px",
            opacity: "0",
            transitionDelay: "500ms",
            }}
        />
        <img
            src={base64 ? base64 : attrPropSet.base64 ? attrPropSet.base64 : srcSet ? srcSet.split(",")[0].split(" ")[0] : src}
            title={attrPropSet.title ? attrPropSet.title : ''}
            alt={attrPropSet.title ? attrPropSet.title : src}
            style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center center",
                filter: "blur(50px)", // for a nicer looking blur
                transform: "scale(1)", // this is needed so Safari keeps sharp edges
                transition: "opacity .5s ease-in-out",
                opacity: imgLoaded ? "0" : "1"
            }}
        />
      {/* {inView || supportsLazyLoading ? ( */}
      {inView ? (
        <picture>
          <source srcSet={srcSet} />
          <img
            ref={imgRef}
            srcSet={srcSet ? srcSet : src}
            src={srcSet ? srcSet.split(",")[0].split(" ")[0] : src} // get first (lowest quality) entry in srcSet
            sizes={sizes}
            title={attrPropSet.title ? attrPropSet.title : ''}
            alt={attrPropSet.title ? attrPropSet.title : src}
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center center",
              opacity: "0",
              transition: "opacity .5s ease-in-out", // can change timing here
            }}
            loading="lazy"
            className="lazy"
            onLoad={() => {
                imgRef.current.style.opacity = "1"
                setImgLoaded(true)
            }}
          />
        </picture>
      ) : null}
        <noscript>
            <picture>
            <source srcSet={srcSet} />
            <img
                srcSet={srcSet ? srcSet : src}
                src={srcSet ? srcSet.split(",")[0].split(" ")[0] : src}
                sizes={sizes}
                title={attrPropSet.title ? attrPropSet.title : ''}
                alt={attrPropSet.title ? attrPropSet.title : src}
                style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center center",
                opacity: "0",
                transition: "opacity .5s ease-in-out", // can change timing here
                }}
                loading="lazy"
                className="lazy"
                ref={imgRef}
                onLoad={() => {
                    imgRef.current.style.opacity = "1"
                }}
            />
            </picture>
        </noscript>
      </div>
    )
}

export default LazyImage