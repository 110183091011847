import React, { useRef, useState } from "react"
import useNativeLazyLoading from "@charlietango/use-native-lazy-loading"
import { useInView } from "react-intersection-observer"
import { SecondaryOutlineButton } from "./button"
import commonUtil from "../utils/common-util"

const MarkupButton = ({
    children,
    size,
    href,
    'click-event-config': clickEventConfig,
    ...props
}) => {

    // console.log("###%$@$%### MarkupButton : props => ", props)
    // console.log("###%$@$%### MarkupButton : clickEventConfig => ", clickEventConfig)
    // console.log("###%$@$%### MarkupButton : size => ", size)
    // console.log("###%$@$%### MarkupButton : href => ", href)

    return <SecondaryOutlineButton
        url={href}
        size={size}
        {...(
            clickEventConfig !== undefined ?
                {
                    gTagEvent: commonUtil.stringConfigToProspSet({ string: clickEventConfig })
                } : null
        )}
        {...props}
    >{ children }</SecondaryOutlineButton>
}

export default MarkupButton