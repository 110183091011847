/** @jsx jsx */
import React, { useRef, useEffect, useState, useCallback, useLayoutEffect } from 'react'
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import { useInView } from "react-intersection-observer"
import Layout from "@lekoarts/gatsby-theme-cara/src/components/layout"
import rehypeReact from "rehype-react"
import { COLORS_HEX, CONTENT_CONTAINER_WIDTH, FONT_SIZES } from '../constants/constant'
import AppContext from '../context/AppContext'
import useWindowDimensions from '../utils/use-window-dimensions'
import Footer from '../components/footer'
import LazyImage from "../components/lazy-image"
import MarkupButton from '../components/markup-button'

const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: {
      "img": LazyImage,
      "a": MarkupButton
    },
}).Compiler

const EventPage = ({path, pageContext}) => {
  
// console.log('>>>>>> ?? EventPage path ?? <<<<<< : ', path)
//   Below are code for temporary redirection.
//   if (path == '/nft') {
//     document.location = 'https://cdn.platypus.finance/NFT_Brochure.pdf'
//     // document.location = 'https://platypus.finance'
//     // setTimeout(function(){
//     //     document.location = 'https://platypus.finance'
//     // },1500)
//   }
//   Code for temporary redirection end at above line.

  const metaConfigs = {
    siteTitle: 'Platopia: Dive into the world of Gamified Liquidity Mining',
    siteDescription: "Here's all you need to know about Platypus' gamified edition. It uncovers the magical world of Platopia & how NFTs can give you a supreme advantage. Liquidity mining has never been more thrilling!",
    siteImage: "/platypus-nft-press-banner.jpg",
    siteTitleTemplate: `%s`,
    ...(path ? {sitePath: path + '/'} : null)
  }

  const {
    items,
    addMenuItem,
    resetMenuItems,
    reviseMenuItem,
    setRecalculationOnResize,
    setHeaderTransparency,
    setSectionHighlights,
    setInstructions
  } = React.useContext(AppContext);
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();
  const { hideNavi } = pageContext !== undefined && pageContext.hideNavi !== undefined ? pageContext : { hideNavi : false }


  const data = useStaticQuery(graphql`
    {
        allMarkdownRemark(
            filter: {fields: {slug: {in: ["/comic-platopia/", "/comic-mining/", "/content-mining/", "/content-nft/", "/content-hero-spec/", "/content-mint/", "/content-gameplay/", "/content-avalanche/"]}}}
        ) {
            edges {
                node {
                    fields {
                        slug
                    }
                    htmlAst
                }
            }
        }
    }
  `)

  console.log('GRAPHQL => data >>> ', data)
//   console.log('GRAPHQL => edges >>> ', data.allMarkdownRemark.edges)
//   console.log('GRAPHQL => filter >>> ', data.allMarkdownRemark.edges.filter(itm=>itm.node.fields.slug=='/comic-mining/')[0])

  useEffect(() => {
    // document.querySelector('#gatsby-focus-wrapper main > div').addEventListener('scroll', handleScroll);
    // console.log('███O███')
    // console.log('██OOO██')
    // console.log('█OO█OO█')
    // console.log('██OOO██')
    // console.log('███O███')
    // console.log('CHECK')
    resetMenuItems();
    setRecalculationOnResize(false);
    setHeaderTransparency(false);
    addMenuItem([{
      label: 'Storyline',
      href: '/nft',
      anchor: '#storyline',
      target: '_self',
      eventProps: {
        event_action: 'Click on {location}',
        event_category: 'Content Reading',
        event_label: 'Storyline'
      },
      viewability: '101',
      area: 'anchor-links'
    },{
      label: 'Plot Summary',
      href: '/nft',
      anchor: '#plot-summary',
      target: '_self',
      eventProps: {
        event_action: 'Click on {location}',
        event_category: 'Content Reading',
        event_label: 'Plot Summary'
      },
      viewability: '101',
      area: 'anchor-links'
    },{
      label: 'About Liquidity Mining',
      href: '/nft',
      anchor: '#liquidity-mining',
      target: '_self',
      eventProps: {
        event_action: 'Click on {location}',
        event_category: 'Content Reading',
        event_label: 'Liquidity Mining'
      },
      viewability: '101',
      area: 'anchor-links'
    },{
      label: 'NFT',
      href: '/nft',
      anchor: '#nft',
      target: '_self',
      eventProps: {
        event_action: 'Click on {location}',
        event_category: 'Content Reading',
        event_label: 'NFT'
      },
      viewability: '101',
      area: 'anchor-links'
    },{
      label: 'Gameplay',
      href: '/nft',
      anchor: '#gameplay',
      target: '_self',
      eventProps: {
        event_action: 'Click on {location}',
        event_category: 'Content Reading',
        event_label: 'Gameplay'
      },
      viewability: '101',
      area: 'anchor-links',
      className: 'last-anchor-link'
    }], 0)
    // reviseMenuItem({ viewability: '111' },1);
    setInstructions({ key: 'on-mobile-menu-open-button', value: 'Section Navigations' });
  }, []);
  
  useEffect(()=>{
    console.log('^&^&^&^ Menu items changed !!');
    console.log(items);
  },[items])

  /*
    const { ref: secStorylineRef, inView: secStorylineIsInView } = useInView();
    const { ref: secPlotSummaryRef, inView: secPlotSummaryIsInView } = useInView();
    const { ref: secLiquidityMiningRef, inView: secLiquidityMiningIsInView } = useInView();
    const { ref: secNftRef, inView: secNftIsInView } = useInView();
    const { ref: secGameplayRef, inView: secGameplayIsInView } = useInView();
  */

  const { ref: secStorylineRef, inView: secStorylineIsInView } = useInView();
  const { ref: secPlotSummaryRef, inView: secPlotSummaryIsInView } = useInView();
  const { ref: secLiquidityMiningRef, inView: secLiquidityMiningIsInView } = useInView();
  const { ref: secNftRef, inView: secNftIsInView } = useInView();
  const { ref: secGameplayRef, inView: secGameplayIsInView } = useInView();

  useEffect(()=>{
    console.log('▓░▒▒▒░▓▓▓▓▒░░▒▓▓▓▓░▒▒▒░▓');
    console.log('▓▓░▒▒▒▒▒▒▓▓▒▒▓▓▒▒▒▒▒▒░▓▓');
    console.log('▓ Section Inview Status Updates ▓');
    // console.log('▓ Storyline Ref : ▓', secStorylineRef);
    console.log('▓ Storyline : ▓', secStorylineIsInView);
    // console.log('▓ Plot Summary Ref : ▓', secPlotSummaryRef);
    console.log('▓ Plot Summary : ▓', secPlotSummaryIsInView);
    // console.log('▓ Liquidity Mining Ref : ▓', secLiquidityMiningRef);
    console.log('▓ Liquidity Mining : ▓', secLiquidityMiningIsInView);
    console.log('▓ Nft : ▓', secNftIsInView);
    // console.log('▓ Gameplay Ref : ▓', secGameplayRef);
    console.log('▓ Gameplay : ▓', secGameplayIsInView);
    // console.log('▓ Sum Up : ▓', [
    //   secStorylineIsInView,
    //   secPlotSummaryIsInView,
    //   secLiquidityMiningIsInView,
    //   secNftIsInView,
    //   secGameplayIsInView
    // ].reduce((prev, curv)=> {
    //   return prev + curv ? 1 : 0;
    // }, 0))
    const sectionsInviewStatus = [
      secStorylineIsInView,
      secPlotSummaryIsInView,
      secLiquidityMiningIsInView,
      secNftIsInView,
      secGameplayIsInView
    ];
    if (
      sectionsInviewStatus.reduce((prev, curv)=> {
        return prev + curv ? 1 : 0;
      }, 0) <= 1
    ) {
      console.log('▓░░▒▒░░▒▓▒▒░░▒▒▓▒░░▒▒░░▓');
      console.log('░▒▓░░▒▒▒▒▒▓▒▒▓▒▒▒▒▒░░▓▒░');
      console.log('▒▓░▓░▒▒▒▒▒▒▓▓▒▒▒▒▒▒░▓░▓▒');
      console.log('▓ The in view section is : ▓', sectionsInviewStatus.indexOf(true))
      setSectionHighlights({ key: 'anchor-links', value: sectionsInviewStatus.indexOf(true) });
    }

  },[
    secStorylineIsInView,
    secPlotSummaryIsInView,
    secLiquidityMiningIsInView,
    secNftIsInView,
    secGameplayIsInView,
    // secStorylineRef,
    // secPlotSummaryRef,
    // secLiquidityMiningRef,
    // secGameplayRef
  ])

  /*
    const metaConfigs = {
      siteTitle: 'Platopia: Dive into the world of Gamified Liquidity Mining',
      siteDescription: "Here's all you need to know about Platypus' gamified edition. It uncovers the magical world of Platopia & how NFTs can give you a supreme advantage. Liquidity mining has never been more thrilling!",
      sitePath: path + '/',
      siteImage: "/platypus-nft-press-banner.jpg"
    }
  */

  return (
    <Layout
        {...(pageContext !== undefined ? {pageContext : pageContext} : null)}
        {...(metaConfigs)}
    >
      <div sx={{
        // paddingTop: `3rem`,
        // paddingBottom: `5.5rem`,
        h1: {
          fontSize: [
            `${ windowWidth < 360 ? `8.9vw` : `232.51%`}`,
            null,
            `3.445rem`
          ]
        },
        h4: {
          // fontSize: [`1.444rem`, null, `1.875rem`, null, `2.0625rem`],
          fontSize: FONT_SIZES.subtitle,
          fontWeight: 500,
        },
        'p, li, a, .table-row > *': {
          fontSize: FONT_SIZES.p,
        },
        '.text-note': {
          fontSize: FONT_SIZES.note
        },
        '.text-subtitle, .text-subtitle > *, .table-row.row-text-subtitle > *': {
          fontSize: FONT_SIZES.subtitle2
        },
        '.text-subtitle-sm, .text-subtitle-sm > *, .table-row.row-text-subtitle-sm > *': {
          fontSize: FONT_SIZES.subtitle2Sm
        },
        '.text-p-sm, .text-p-sm > *, .table-row.row-text-p-sm > *': {
          fontSize: FONT_SIZES.pSm
        },
        'p, li': {
          color: `rgba(255, 255, 255, 0.7)`,
          '& > b': {
            color: `rgba(255, 255, 255, 1)`,
          }
        },
        a: {
          color: COLORS_HEX.primary
        },
        'p, ol, al, a': {
          '& + h2': {
            marginTop: `1.8rem`
          }
        }
      }} className='article-reading event-page'>
        {/* <ComicPlatopia /> */}
        {/* <p>Redirect back to main page...</p> */}
        <div sx={{
            width: CONTENT_CONTAINER_WIDTH.map((unit, index)=>index < 1?`calc(100% - 2rem)`:unit),
            marginX: `auto`,
        }}>
          <div ref={secStorylineRef} className={'pt-5-otrd'}>
            <div id="storyline"></div>
            {renderAst(data.allMarkdownRemark.edges.filter(itm=>itm.node.fields.slug=='/comic-platopia/')[0].node.htmlAst)}
          </div>
          <div ref={secPlotSummaryRef} className={'pt-5-otrd pb-2'}>
            <div id="plot-summary"></div>
            {renderAst(data.allMarkdownRemark.edges.filter(itm=>itm.node.fields.slug=='/comic-mining/')[0].node.htmlAst)}
          </div>
        </div>
        <div ref={secLiquidityMiningRef} className={'pt-5-otrd'}>
          <div id="liquidity-mining" className={'content-separator mb-4-half'} />
          <div sx={{
              width: CONTENT_CONTAINER_WIDTH.map((unit, index)=>index < 1?`calc(100% - 2rem)`:unit),
              marginX: `auto`,
          }}>
              {renderAst(data.allMarkdownRemark.edges.filter(itm=>itm.node.fields.slug=='/content-mining/')[0].node.htmlAst)}
          </div>
        </div>
        <div ref={secNftRef} className={'pt-5-otrd'}>
          <div id="nft" className={'content-separator'} />
          <div sx={{
              width: CONTENT_CONTAINER_WIDTH.map((unit, index)=>index < 1?`calc(100% - 2rem)`:unit),
              marginX: `auto`,
          }}>
              {renderAst(data.allMarkdownRemark.edges.filter(itm=>itm.node.fields.slug=='/content-nft/')[0].node.htmlAst)}
          </div>
          <div class="bg-nero">
            <div sx={{
                width: CONTENT_CONTAINER_WIDTH.map((unit, index)=>index < 1?`calc(100% - 2rem)`:unit),
                marginX: `auto`,
            }}>
              {renderAst(data.allMarkdownRemark.edges.filter(itm=>itm.node.fields.slug=='/content-hero-spec/')[0].node.htmlAst)}
            </div>
          </div>
          <div sx={{
              width: CONTENT_CONTAINER_WIDTH.map((unit, index)=>index < 1?`calc(100% - 2rem)`:unit),
              marginX: `auto`,
          }}>
              {renderAst(data.allMarkdownRemark.edges.filter(itm=>itm.node.fields.slug=='/content-mint/')[0].node.htmlAst)}
          </div>
        </div>
        <div ref={secGameplayRef} className={'pt-5-otrd'}>
          <div id="gameplay" className={'content-separator mb-5-half'} />
          <div sx={{
              width: CONTENT_CONTAINER_WIDTH.map((unit, index)=>index < 1?`calc(100% - 2rem)`:unit),
              marginX: `auto`,
          }}>
              {renderAst(data.allMarkdownRemark.edges.filter(itm=>itm.node.fields.slug=='/content-gameplay/')[0].node.htmlAst)}
          </div>
          <div class="bg-nero">
            <div sx={{
                width: CONTENT_CONTAINER_WIDTH.map((unit, index)=>index < 1?`calc(100% - 2rem)`:unit),
                marginX: `auto`,
            }}>
              {renderAst(data.allMarkdownRemark.edges.filter(itm=>itm.node.fields.slug=='/content-avalanche/')[0].node.htmlAst)}
            </div>
          </div>
        </div>
      </div>
      {!hideNavi && <Footer menuItems={items}/>}
    </Layout>
  )
}

// export const query = graphql`
//   query ($for: String!) {
//     for
//   }
// `

export default EventPage